import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit, useAuth} from './modules/auth'
import {Steps} from 'intro.js-react'
import {useTutorial} from './modules/tutorial/hooks/useTutorial'

const App = () => {
  const {currentUser} = useAuth()
  const {stepsEnabled, steps, handleExit, setStepsEnabled} = useTutorial()

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
            {currentUser?.onboarded && (
              <Steps
                enabled={stepsEnabled}
                steps={steps}
                initialStep={0}
                onExit={handleExit}
                onBeforeExit={() => setStepsEnabled(false)}
                options={{
                  tooltipClass: 'text-dark bg-light',
                  nextLabel: 'Avançar',
                  prevLabel: 'Voltar',
                  doneLabel: 'Concluir',
                  exitOnOverlayClick: false,
                }}
              />
            )}
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
