import axios, {AxiosResponse} from 'axios'
import {ChangePasswordData, ResponseChangePassword} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/api/usuarios`

function updatePassword(data: ChangePasswordData): Promise<ResponseChangePassword> {
  return axios
    .patch(`${USER_URL}/${data.user_id}/update-password`, data)
    .then((response: AxiosResponse<ResponseChangePassword>) => response.data)
}

export {updatePassword}
