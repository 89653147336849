import {useState, useCallback, useEffect} from 'react'
import {sidebarSteps, navbarSteps} from '../tutorialSteps'
import {useMarkTutorialAsCompleted} from '../../user-tutorial/hooks/useMarkTutorialAsCompleted'
import {useAuth} from '../../auth'

interface Step {
  element: string
  title: string
  intro: string
}

export const useTutorial = () => {
  const [activeTutorial, setActiveTutorial] = useState<string>()
  const {currentUser} = useAuth()
  const [stepsEnabled, setStepsEnabled] = useState(false)
  const {mutateAsync: markTutorialAsCompletedFn} = useMarkTutorialAsCompleted()
  const [steps, setSteps] = useState<Step[]>([])

  useEffect(() => {
    if (!currentUser) return
    
    const incompletedTutorial = currentUser.tutorials.find((tutorial) => !tutorial.is_completed)
    if (incompletedTutorial) {
      setStepsEnabled(!incompletedTutorial.is_completed)
      setActiveTutorial(incompletedTutorial.name)
    }
  }, [currentUser])

  useEffect(() => {
    switch (activeTutorial) {
      case 'sidebar':
        setSteps(sidebarSteps)
        break
      case 'navbar':
        setSteps(navbarSteps)
        break
      default:
        setSteps([])
    }
  }, [activeTutorial])

  const handleExit = useCallback(async () => {
    if (!activeTutorial) return

    const tutorialToMark = currentUser?.tutorials?.find(
      (tutorial) => tutorial.name === activeTutorial
    )

    if (tutorialToMark) {
      await markTutorialAsCompletedFn({
        userId: currentUser?.id!,
        tutorialId: tutorialToMark.id,
      })
    }

    const nextTutorial = currentUser?.tutorials?.find(
      (tutorial) => tutorial.name !== activeTutorial && !tutorial.is_completed
    )

    if (nextTutorial) {
      setActiveTutorial(nextTutorial.name)
    } else {
      setStepsEnabled(false)
    }
  }, [activeTutorial, currentUser, markTutorialAsCompletedFn])

  return {
    stepsEnabled,
    steps,
    setStepsEnabled,
    activeTutorial,
    handleExit,
  }
}
