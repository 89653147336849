import {useMutation, useQueryClient} from 'react-query'
import {markTutorialAsCompleted} from '../core/_requests'
import {MarkTutorialAsCompletedData} from '../core/_models'
import {useAuth} from '../../auth'

export function useMarkTutorialAsCompleted() {
  const {setCurrentUser} = useAuth()

  return useMutation({
    mutationFn: async (data: MarkTutorialAsCompletedData) => await markTutorialAsCompleted(data),
    onSuccess(data, variables, context) {
      setCurrentUser((prevData) => {
        if (!prevData) return
        const updatedTutorials =
          prevData?.tutorials.filter((tutorial) => tutorial.id != variables.tutorialId) || []

        return {...prevData, tutorials: [...updatedTutorials, data]}
      })
    },
  })
}
