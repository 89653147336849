import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../_metronic/helpers'
import {Notification} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const NOTIFICATIONS_URL = `${API_URL}/api/notifications`

const getNotifications = (userId: ID): Promise<Notification[]> => {
  return axios
    .get(`${NOTIFICATIONS_URL}/${userId}`)
    .then((response: AxiosResponse<Notification[]>) => response.data)
}

const updateUserNotificationPreferences = (
  userId: ID,
  data: {id: number; enabled_email: boolean; enabled_system: boolean}[]
): Promise<any> => {
  return axios
    .put(`${NOTIFICATIONS_URL}/${userId}/update-notifications`, {notifications: data})
    .then((d: AxiosResponse<any>) => d.data)
}

export {getNotifications, updateUserNotificationPreferences}
