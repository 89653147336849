import {Comment} from '../../core/_models'
import {useThemeMode} from '../../../../../../../_metronic/partials'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {FC} from 'react'

const auditableColumns: Record<string, string> = {
  name: 'título da tarefa',
  begin_time: 'início',
  end_time: 'término',
  total_time: 'tempo trabalhado',
  play: 'play',
  status: 'status',
  user: 'usuário',
  expected_date: 'data prevista de entrega',
  project_id: 'projeto',
  description: 'descrição',
}

type TaskCommentsProps = {
  comments: Comment[]
}

export const TaskComments: FC<TaskCommentsProps> = ({comments}) => {
  const {mode} = useThemeMode()

  return (
    <div id='kt_task_comments_pane' className='tab-pane fade h-100'>
      <div className='pt-4 d-flex flex-column-reverse gap-4 overflow-scroll h-100'>
        {comments?.map((comment) => (
          <>
            <div className='d-flex align-items-center space-4'>
              <div className='symbol symbol-circle symbol-30px me-5'>
                {comment.user?.avatar && mode === 'dark' ? (
                  <img
                    alt={comment.user.name}
                    className='object-fit-cover'
                    src={
                      isNotEmpty(comment.user.avatar) && comment.user.avatar !== 'null'
                        ? process.env.REACT_APP_THEME_API_URL + '/storage/' + comment.user.avatar
                        : toAbsoluteUrl('/media/svg/files/blank-image-dark.svg')
                    }
                  />
                ) : (
                  <img
                    alt='Logo'
                    className='object-fit-cover'
                    src={
                      isNotEmpty(comment.user?.avatar) && comment.user?.avatar !== 'null'
                        ? process.env.REACT_APP_THEME_API_URL + '/storage/' + comment.user?.avatar
                        : toAbsoluteUrl('/media/svg/files/blank-image.svg')
                    }
                  />
                )}
              </div>
              <p>
                {comment.user?.name}{' '}
                {comment.event == 'updated' && (
                  <>
                    {comment.auditable_type == 'App\\Models\\Api\\TimeTracking' ? (
                      `pausou o trabalho na tarefa.`
                    ) : (
                      <>
                        {comment.details &&
                          Object.keys(comment.details.old).map((key, index) => (
                            <>
                              {!['end_date'].includes(key) && (
                                <>
                                  {comment.details?.old[key]
                                    ? key !== 'project_id'
                                      ? `atualizou o campo ${auditableColumns[key]} de ${comment.details.old[key]} para ${comment.details.new[key]}.`
                                      : 'atualizou o projeto.'
                                    : `definiu o campo ${auditableColumns[key]} para ${
                                        ['expected_date'].includes(key)
                                          ? new Date(
                                              comment.details?.new[key]?.toString()
                                            ).toLocaleDateString('pt-BR', {
                                              timeZone: 'UTC',
                                            })
                                          : `"${comment.details?.new[key]}"`
                                      }.`}
                                </>
                              )}
                            </>
                          ))}
                      </>
                    )}
                  </>
                )}
                {comment.event == 'created' && (
                  <>
                    {comment.auditable_type == 'App\\Models\\Api\\TimeTracking' && (
                      <>
                        {!!comment.details?.play
                          ? 'iniciou trabalho na tarefa.'
                          : comment.details?.total_time! >= 0
                          ? 'adicionou tempo manualmente na tarefa.'
                          : 'removeu tempo manualmente na tarefa.'}
                      </>
                    )}
                    {comment.auditable_type == 'App\\Models\\Api\\TaskAttachment' &&
                      'adicionou um anexo na tarefa.'}
                    {comment.auditable_type == 'App\\Models\\Api\\TaskResponsible' &&
                      `adicionou o usuário ${comment.details?.user_name} na tarefa.`}
                  </>
                )}
                {comment.event == 'deleted' && (
                  <>
                    {comment.auditable_type == 'App\\Models\\Api\\TaskAttachment' &&
                      'removeu um anexo da tarefa.'}
                    {comment.auditable_type == 'App\\Models\\Api\\TaskResponsible' &&
                      `removeu o usuário ${comment.details?.user_name} da tarefa.`}
                  </>
                )}
                <i className='text-gray'>
                {' '}-{' '}
                  {new Date(comment.when.toString()).toLocaleDateString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                  })}
                </i>
              </p>
            </div>
          </>
        ))}
      </div>
    </div>
  )
}
