import {Route, Routes, Outlet} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {getNotifications, updateUserNotificationPreferences} from './core/_requests'
import {useAuth} from '../auth'
import {Toast} from '../../../_metronic/partials/widgets'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

export default function NotificationsPage() {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const queryClient = useQueryClient()
  const [changes, setChanges] = useState<any[]>([])

  const {data: notifications} = useQuery(
    `userNotificationPreferences`,
    () => getNotifications(currentUser?.id),
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )

  const {mutateAsync: updateUserNotificationPreferencesFn, isLoading} = useMutation({
    mutationFn: async (changes: any[]) => {
      return await updateUserNotificationPreferences(currentUser?.id, changes ?? [])
    },
    onSuccess() {
      queryClient.invalidateQueries(['userNotificationPreferences'])

      Toast.fire({
        icon: 'success',
        title: 'Notificações atualizadas com sucesso!',
        customClass: {
          popup: 'bg-light-success',
          title: 'text-success',
        },
      })
    },
    onError(error: any) {
      Toast.fire({
        icon: 'error',
        title: 'Erro ao atualizar notificações',
        text: error.response.data.message,
        customClass: {
          popup: 'bg-light-danger',
          title: 'text-danger',
          htmlContainer: 'text-danger',
        },
      })
    },
  })

  const handleChange = (id: any, type: any, value: any) => {
    setChanges((prevChanges) => {
      const updatedChanges = [...prevChanges]
      const existingChange = updatedChanges.find((change) => change.id === id)

      if (existingChange) existingChange[type] = value
      else updatedChanges.push({id, [type]: value})

      return updatedChanges
    })
  }

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/'
          element={
            <div style={{marginTop: '-20px'}}>
              <PageTitle>{intl.formatMessage({id: 'MENU.CONFIG_NOTIFICATIONS'})}</PageTitle>
              <p className='mb-8 fs-7'>
                Nesta página, você pode gerenciar as suas preferências de notificações. Ative ou
                desative alertas por e-mail ou no sistema conforme sua necessidade.
              </p>
              <div className='card mb-5 mb-xl-10'>
                <form className='form'>
                  <div className='card-body px-9 pt-3 pb-4'>
                    <div className='table-responsive'>
                      <table className='table table-row-dashed border-gray-300 align-middle gy-6'>
                        <tbody className='fs-6 fw-bold'>
                          <tr>
                            <td className='min-w-250px fs-4 fw-bolder'>
                              {intl.formatMessage({id: 'MENU.NOTIFICATIONS'})}
                            </td>
                            <td className='w-125px px-3'>
                              <div className='form-check form-check-solid px-0 mx-0 text-center'>
                                <label
                                  className='form-check-label'
                                  htmlFor='kt_settings_notification_system'
                                >
                                  Sistema
                                </label>
                              </div>
                            </td>
                            <td className='w-125px px-3'>
                              <div className='form-check form-check-solid px-0 mx-0 text-center'>
                                <label
                                  className='form-check-label'
                                  htmlFor='kt_settings_notification_email'
                                >
                                  Email
                                </label>
                              </div>
                            </td>
                          </tr>
                          {notifications?.map((notification) =>
                            notification.user_preferences.map((preferences) => (
                              <tr>
                                <td className='border-bottom-0'>
                                  {notification.name}
                                  <OverlayTrigger
                                    placement='top'
                                    delay={{show: 50, hide: 100}}
                                    overlay={<Tooltip>{notification.description}</Tooltip>}
                                  >
                                    <i className='d-md-none fas fa-exclamation-circle ms-2 fs-7'></i>
                                  </OverlayTrigger>
                                  <span className='d-none d-md-inline fw-normal fs-7 ms-2'>
                                    <i>- {notification.description}</i>
                                  </span>
                                </td>
                                <td className='w-125px border-bottom-0 px-3'>
                                  <div className='form-check form-check-solid px-0 d-flex'>
                                    <input
                                      className='form-check-input mx-auto'
                                      type='checkbox'
                                      value=''
                                      defaultChecked={preferences.pivot.system_enabled}
                                      onChange={(e) =>
                                        handleChange(
                                          notification.id,
                                          'system_enabled',
                                          e.target.checked
                                        )
                                      }
                                      disabled={!notification.has_system}
                                    />
                                  </div>
                                </td>
                                <td className='w-125px border-bottom-0 px-3'>
                                  <div className='form-check form-check-solid px-0 d-flex'>
                                    <input
                                      className='form-check-input mx-auto'
                                      type='checkbox'
                                      value=''
                                      defaultChecked={preferences.pivot.email_enabled}
                                      onChange={(e) =>
                                        handleChange(
                                          notification.id,
                                          'email_enabled',
                                          e.target.checked
                                        )
                                      }
                                      disabled={!notification.has_email}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    <button
                      type='button'
                      className='btn btn-sm btn-primary'
                      disabled={isLoading}
                      onClick={() => updateUserNotificationPreferencesFn(changes)}
                    >
                      {!isLoading && 'Salvar Alterações'}
                      {isLoading && (
                        <span className=''>
                          {intl.formatMessage({id: 'GENERAL.LOADING'})}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          }
        />
      </Route>
    </Routes>
  )
}
