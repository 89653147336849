/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Languages} from './Languages'
import {UserModal} from '../../modals/user-create-modal-stepper/UserModal'
import {CompanyModal} from '../../modals/company-edit-modal/CompanyModal'
import {useAuth} from '../../../../app/modules/auth'
import {isNotEmpty, toAbsoluteUrl} from '../../../helpers'
import {getUserById} from '../../modals/user-create-modal-stepper/core/_requests'
import {useIntl} from 'react-intl'
import {PasswordModal} from '../../modals/password-modal/PasswordModal'

const HeaderUserMenu: FC = () => {
  const intl = useIntl()
  const {currentUser, logout} = useAuth()
  const [showModalStepper, setShowModalStepper] = useState<boolean>(false)
  const [showModalCompany, setShowModalCompany] = useState<boolean>(false)
  const [showModalPassword, setShowModalPassword] = useState<boolean>(false)
  const openEditModal = () => {
    setShowModalStepper(true)
  }
  const openEditModalComapany = () => {
    setShowModalCompany(true)
  }

  const currentTheme = window.localStorage.getItem('kt_theme_mode_value') || ''

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              className='object-fit-cover'
              alt={currentUser?.name}
              src={
                isNotEmpty(currentUser?.avatar) && currentUser?.avatar !== 'null'
                  ? process.env.REACT_APP_THEME_API_URL + '/storage/' + currentUser?.avatar
                  : toAbsoluteUrl(
                      currentTheme === 'dark'
                        ? '/media/svg/files/blank-image-dark.svg'
                        : '/media/svg/files/blank-image.svg'
                    )
              }
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.name}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>
                {currentUser?.administrator ? 'Admin' : ''}
              </span>
            </div>
            <span className='fw-bold text-muted fs-7'>{currentUser?.email}</span>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a className='menu-link px-5' onClick={openEditModal}>
          Meu Perfil
          {/* {intl.formatMessage({id: 'TABLE.GENERAL.LABEL.EDIT'})} */}
        </a>
        <UserModal
          show={showModalStepper}
          handleClose={() => setShowModalStepper(false)}
          userId={currentUser?.id}
        />
      </div>

      {currentUser?.administrator ? (
        <>
          <div className='menu-item px-5'>
            <a className='menu-link px-5' onClick={openEditModalComapany}>
              <span className='menu-text'>Conta da Empresa</span>
              {/* <span className='menu-badge'>
              <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
              </span> */}
            </a>
          </div>
          <CompanyModal
            show={showModalCompany}
            handleClose={() => setShowModalCompany(false)}
            companyId={currentUser?.instance_id}
          />
        </>
      ) : null}

      {currentUser?.administrator && !currentUser.manager ? (
        <div className='menu-item px-5'>
          <Link to='/billings' className='menu-link px-5'>
            <span className='menu-text'>{intl.formatMessage({id: 'MENU.BILLING'})}</span>
          </Link>
        </div>
      ) : null}

      {currentUser?.administrator ? (
        <>
          <div
            className='menu-item px-5'
            data-kt-menu-trigger='hover'
            data-kt-menu-placement='left-start'
            data-kt-menu-flip='bottom'
          >
            <a href='#' className='menu-link px-5'>
              <span className='menu-title'>Configurações Gerais</span>
              <span className='menu-arrow'></span>
            </a>

            <div className='menu-sub menu-sub-dropdown w-175px py-4'>
              {/* <div className='menu-item px-3'>
                <Link to='/stages' className='menu-link d-flex flex-stack px-5'>
                  {intl.formatMessage({id: 'MENU.STAGES'})}
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title={intl.formatMessage({id: 'MENU.STAGES'})}
                  ></i>
                </Link>
              </div> */}

              <div className='menu-item px-3'>
                <Link to='/notifications' className='menu-link d-flex flex-stack px-5'>
                  Notificações
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Gerencie suas notificações'
                  ></i>
                </Link>
              </div>
              <div className='menu-item px-3'>
                <Link to='/holidays' className='menu-link d-flex flex-stack px-5'>
                  Feriados
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='View your statements'
                  ></i>
                </Link>
              </div>
              {!currentUser?.manager && (
                <div className='menu-item px-3'>
                  <Link to='/configs' className='menu-link d-flex flex-stack px-5'>
                    Configurações da {currentUser.instance?.name}
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='View your statements'
                    ></i>
                  </Link>
                </div>
              )}

              {/* <div className='separator my-2'></div>

              <div className='menu-item px-3'>
                <div className='menu-content px-3'>
                  <label className='form-check form-switch form-check-custom form-check-solid'>
                    <input
                      className='form-check-input w-30px h-20px'
                      type='checkbox'
                      value='1'
                      defaultChecked={true}
                      name='notifications'
                    />
                    <span className='form-check-label text-muted fs-7'>Notificações</span>
                  </label>
                </div>
              </div> */}
            </div>
          </div>
        </>
      ) : null}

      <div className='menu-item px-5'>
        <div className='menu-link px-5' onClick={() => setShowModalPassword(true)}>
          <span className='menu-text'>{intl.formatMessage({id: 'MENU.CHANGE_PASSWORD'})}</span>
        </div>
      </div>

      <PasswordModal show={showModalPassword} handleClose={() => setShowModalPassword(false)} />

      <div className='separator my-2'></div>

      {/* <Languages /> */}

      {/* <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Configurar notificações
        </Link>
      </div> */}

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sair
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
