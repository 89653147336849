import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {intl.formatMessage({id: 'MENU.TITLE.DADHBOARD'})}
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {intl.formatMessage({id: 'MENU.TITLE.MANAGEMENT'})}
          </span>
        </div>
      </div>

      {currentUser?.administrator ? (
        <>
          <SidebarMenuItemWithSub
            to='#'
            title={intl.formatMessage({id: 'MENU.TEAMS_AND_USERS'})}
            fontIcon='bi-archive'
            icon='/media/icons/duotune/communication/com014.svg'
          >
            <SidebarMenuItem
              to='/teams'
              title={intl.formatMessage({id: 'MENU.TEAMS'})}
              hasBullet={true}
            />
            <SidebarMenuItem
              to='/users'
              title={intl.formatMessage({id: 'MENU.USERS'})}
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>
          <SidebarMenuItem
            icon='/media/icons/duotune/graphs/gra010.svg'
            to='/reports'
            title={intl.formatMessage({id: 'MENU.REPORTS'})}
          />
        </>
      ) : null}

      <SidebarMenuItem
        icon='/media/icons/duotune/abstract/abs027.svg'
        to='/projects'
        title={intl.formatMessage({id: 'MENU.PROJECTS'})}
      />
      <SidebarMenuItem
        icon='/media/icons/duotune/art/art002.svg'
        to='/sprints'
        title={intl.formatMessage({id: 'MENU.SPRINTS'})}
      />

      {/* <SidebarMenuItem icon='/media/icons/duotune/general/gen014.svg' to='/calendar' title={intl.formatMessage({id: 'MENU.CALENDAR'})} /> */}
      {/* <SidebarMenuItem icon='/media/icons/duotune/communication/com011.svg' to='/tickets' title={intl.formatMessage({id: 'MENU.TICKETS'})} /> */}
      {currentUser?.administrator ? (
        <>
          <SidebarMenuItem
            icon='/media/icons/duotune/communication/com009.svg'
            to='/schedules'
            title={intl.formatMessage({id: 'MENU.SCHEDULE'})}
          />
          <SidebarMenuItem
            icon='/media/icons/duotune/general/gen013.svg'
            to='/time-tracking'
            title={intl.formatMessage({id: 'MENU.TIME_TRACKING'})}
          />
          {/* <SidebarMenuItem icon='/media/icons/duotune/art/art002.svg' to='/squad-tasks' title={intl.formatMessage({id: 'MENU.SQUARD_TASKS'})} /> */}
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                {intl.formatMessage({id: 'MENU.TITLE.CMS'})}
              </span>
            </div>
          </div>

          <SidebarMenuItem
            icon='/media/icons/duotune/communication/com014.svg'
            to='/clients'
            title={intl.formatMessage({id: 'MENU.CLIENTS'})}
          />
          <SidebarMenuItem
            icon='/media/icons/duotune/files/fil025.svg'
            to='/contracts'
            title={intl.formatMessage({id: 'MENU.CONTRACTS'})}
          />
        </>
      ) : (
        <SidebarMenuItem
          icon='/media/icons/duotune/general/gen013.svg'
          to='/time-tracking-user'
          title={intl.formatMessage({id: 'MENU.TIME_TRACKING'})}
        />
      )}

      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'MENU.TITLE.CULTURE'})}</span>
        </div>
      </div> */}

      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({id: 'MENU.WIKI'})}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen005.svg'
      >
        <SidebarMenuItem
          to='/wiki'
          title={intl.formatMessage({id: 'MENU.POST'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/wiki/wiki-config'
          title={intl.formatMessage({id: 'MENU.SKETCH'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/category'
          title={intl.formatMessage({id: 'MENU.CONFIG_CATEGORY'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/tags'
          title={intl.formatMessage({id: 'MENU.CONFIG_TAGS'})}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
    </>
  )
}

export {SidebarMenuMain}
