import {useQuery} from 'react-query'
import {OverviewProvider} from '../schedule-view/core/OverviewProvider'
import {useParams} from 'react-router-dom'
import {QUERIES} from '../../../../../_metronic/helpers'
import {getProjectByPublicUrl} from '../../../project/components/core/_requests'
import {useState} from 'react'
import {initialProject, Project} from '../../../project/components/core/_models'
import {Loading} from '../../../../../_metronic/partials/components/loading/Loading'
import {useIntl} from 'react-intl'

const DescriptionViewWrapper = () => {
  const intl = useIntl()

  const {projectId} = useParams()
  const [data, setData] = useState<Project & {cycleOption?: string}>(initialProject)

  const {
    isLoading,
    data: project,
    refetch,
    error,
  } = useQuery(
    `${QUERIES.PROJECTS_LIST}-project-${projectId}`,
    () => getProjectByPublicUrl(projectId!),
    {
      cacheTime: 0,
      onError: (err) => (window.location.href = `../404`),
      onSuccess(response) {
        setData(response)
      },
      refetchOnWindowFocus: false,
    }
  )

  if (!project || isLoading) {
    ;<Loading />
  }
  return (
    <OverviewProvider>
      <div className='container mb-15'>
        <div className='d-flex justify-content-between mb-2'>
          <h2 className='fs-1'>
            {intl.formatMessage({id: 'PROJECT.LABEL.SHARED_PROJECT_SCOPE_SUMMARY_VISION'})}{' '}
            {project?.name}{' '}
          </h2>
        </div>
        <div className='row bg-white pt-10 pb-4 px-5 fs-6 text-black'>
          <div className='col-12'>
            <div className='content'>
              <div className={`resume overflow-hidden transition-all 'mh-500px overflow-scroll'}`}>
                {project?.shared_description && (
                  <div dangerouslySetInnerHTML={{__html: project?.shared_description}} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </OverviewProvider>
  )
}

export {DescriptionViewWrapper}
