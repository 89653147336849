import {Modal} from 'react-bootstrap'
import {Loading} from '../../components/loading/Loading'
import {KTSVG, isNotEmpty} from '../../../helpers'
import {useState} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useMutation} from 'react-query'
import Swal from 'sweetalert2'
import clsx from 'clsx'
import {ChangePasswordData, initialPassword} from './core/_models'
import {updatePassword} from './core/_requests'
import { useAuth } from '../../../../app/modules/auth'

type Props = {
  show: boolean
  handleClose: () => void | undefined
}

export function PasswordModal({show, handleClose}: Props) {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const passwordSchema = Yup.object().shape({
    current_password: Yup.string()
      .min(8, intl.formatMessage({id: 'FORM.INPUT.VALIDATION.MIN_LENGTH'}, {min: '8'}))
      .required(intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'})),
    new_password: Yup.string()
      .min(8, intl.formatMessage({id: 'FORM.INPUT.VALIDATION.MIN_LENGTH'}, {min: '8'}))
      .required(intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'})),
    confirm_password: Yup.string().oneOf([Yup.ref('new_password')], 'As senhas devem ser iguais'),
  })

  const {mutateAsync: updatePasswordFn} = useMutation(
    (data: ChangePasswordData) => updatePassword(data),
    {
      onSuccess: (response) => {
        const TemaAtual = window.localStorage.getItem('kt_theme_mode_value') || ''
        const tema = TemaAtual === 'dark' ? '#353b48' : '#fff'
        Swal.fire({
          icon: 'success',
          title: intl.formatMessage({id: 'ALERT.LABEL.UPDATE.TITLE'}),
          html: '<h5>' + response?.message + '</h5>',
          background: tema,
          confirmButtonColor: '#009ef7',
        })
        handleClose()
      },
      onError: (error: any) => {
        Swal.fire({
          icon: 'warning',
          text: error.response.data.message,
        })
      },
    }
  )

  const formik = useFormik({
    initialValues: {...initialPassword, user_id: currentUser?.id},
    validationSchema: passwordSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        console.log(values)
        return updatePasswordFn(values)
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  function onClose() {
    formik.resetForm()
    handleClose()
  }

  return (
    <Modal
      id='kt_modal_edit_project'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-500px'
      show={show}
      onHide={onClose}
      backdrop={true}
    >
      <>
        <div className='modal-header pb-0 border-0 justify-content-end'>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={onClose}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>
        <div className='modal-body scroll-y mx-5 mx-xl-10 pt-0 pb-15'>
          <div className='text-center mb-5'>
            <h1 className='mb-3'>{intl.formatMessage({id: 'MODAL.TITLE.COMPANY'})}</h1>
          </div>
          <form
            id='kt_modal_edit_company_form'
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className='d-flex flex-column scroll-y me-n7'>
              <div className='mb-4'>
                <label className='required fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'FORM.INPUT.NAME.CURRENT_PASSWORD'})}
                </label>

                <input
                  {...formik.getFieldProps('current_password')}
                  type='password'
                  name='current_password'
                  onChange={(e: any) => formik.setFieldValue('current_password', e.target.value)}
                  className={clsx(
                    'form-control form-control-solid py-2 rounded fw-normal',
                    {
                      'is-invalid':
                        formik.touched.current_password && formik.errors.current_password,
                    },
                    {
                      'is-valid':
                        formik.touched.current_password && !formik.errors.current_password,
                    }
                  )}
                  disabled={formik.isSubmitting}
                />

                {formik.touched.current_password && formik.errors.current_password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.current_password}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='mb-4'>
                <label className='required fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'FORM.INPUT.NAME.NEW_PASSWORD'})}
                </label>
                <input
                  {...formik.getFieldProps('new_password')}
                  type='password'
                  name='new_password'
                  onChange={(e: any) => formik.setFieldValue('new_password', e.target.value)}
                  className={clsx(
                    'form-control form-control-solid py-2  rounded fw-normal',
                    {'is-invalid': formik.touched.new_password && formik.errors.new_password},
                    {
                      'is-valid': formik.touched.new_password && !formik.errors.new_password,
                    }
                  )}
                  disabled={formik.isSubmitting}
                />

                {formik.touched.new_password && formik.errors.new_password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.new_password}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='mb-4'>
                <label className='required fw-bold'>
                  {intl.formatMessage({id: 'FORM.INPUT.NAME.CONFIRM_PASSWORD'})}
                </label>

                <input
                  {...formik.getFieldProps('confirm_password')}
                  type='password'
                  name='confirm_password'
                  onChange={(e: any) => formik.setFieldValue('confirm_password', e.target.value)}
                  className={clsx(
                    'form-control form-control-solid py-2  rounded fw-normal',
                    {
                      'is-invalid':
                        formik.touched.confirm_password && formik.errors.confirm_password,
                    },
                    {
                      'is-valid':
                        formik.touched.confirm_password && !formik.errors.confirm_password,
                    }
                  )}
                  disabled={formik.isSubmitting}
                />
                {formik.touched.confirm_password && formik.errors.confirm_password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.confirm_password}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='text-center pt-5'>
                <button type='reset' className='btn btn-light me-5 py-2' onClick={onClose}>
                  {intl.formatMessage({id: 'FORM.GENERAL.CANCEL_BUTTON'})}
                </button>

                <button
                  type='submit'
                  className='btn btn-primary py-2'
                  data-kt-projects-modal-action='submit'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'FORM.GENERAL.SAVE_BUTTON'})}
                  </span>
                  {formik.isSubmitting && (
                    <span className='indicator-progress'>
                      {intl.formatMessage({id: 'GENERAL.LOADING'})}{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
          {formik.isSubmitting && <Loading />}
        </div>
      </>
    </Modal>
  )
}
