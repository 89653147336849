import {Response} from '../../../../../_metronic/helpers'

export type ChangePasswordData = {
  user_id?: number | string
  new_password: string
  current_password: string
  confirm_password: string
}

export type ResponseChangePassword = {
  message: string
  errors: boolean
}

export const initialPassword: ChangePasswordData = {
  new_password: '',
  current_password: '',
  confirm_password: '',
}
