import {useEffect, useState} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {isNotEmpty, KTSVG, toAbsoluteUrl} from '../../../helpers'
import {
  HeaderUserMenu,
  Search,
  ThemeModeSwitcher,
  Helper,
  HeaderNotificationsMenu,
} from '../../../partials'
import {useLayout} from '../../core'
import {Notification, useAuth} from '../../../../app/modules/auth'
import {getUserById} from '../../../partials/modals/user-create-modal-stepper/core/_requests'
import {TaskModal} from '../../../partials/modals/task/TaskModal'
import {useListView} from '../../../partials/modals/task/core/ListViewProvider'
import {useMutation} from 'react-query'
import axios from 'axios'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'
const API_URL = process.env.REACT_APP_API_URL

const Navbar = () => {
  const intl = useIntl()
  const {config} = useLayout()
  const {currentUser} = useAuth()
  const TemaAtual = window.localStorage.getItem('kt_theme_mode_value') || ''
  const [showModalStepper, setShowModalStepper] = useState<boolean>(false)

  const openEditModal = () => {
    setShowModalStepper(true)
  }

  const {mutateAsync: readNotificationsFn} = useMutation((notifications: any) => {
    const ids = notifications.map((notification: any) => notification.id)
    return axios.patch(`${API_URL}/api/notifications/${currentUser?.id}/mark-as-read`, {
      notificationIds: ids,
    })
  })

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item align-items-stretch', itemClass)} id='navbar-search'>
        <Search />
      </div>

      <div className={clsx('app-navbar-item', itemClass)} id='navbar-new-task'>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          onClick={openEditModal}
          className={btnClass}
        >
          <KTSVG path='/media/icons/duotune/general/gen035.svg' className={btnIconClass} />
        </div>
        <TaskModal show={showModalStepper} handleClose={() => setShowModalStepper(false)} />
      </div>

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTSVG path='/media/icons/duotune/communication/com012.svg' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

      <div
        id='navbar-notifications'
        className={clsx('app-navbar-item', itemClass)}
        onClick={() =>
          currentUser?.unread_notifications?.length == 0
            ? {}
            : readNotificationsFn(currentUser?.unread_notifications)
        }
      >
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={`position-relative ${btnClass}`}
        >
          <KTSVG path='/media/icons/duotune/general/gen007.svg' className={btnIconClass} />
          {currentUser?.unread_notifications?.length != 0 && (
            <span className='bullet bullet-dot bg-danger h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
          )}
        </div>
        <HeaderNotificationsMenu />
      </div>

      <div id='navbar-theme' className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>
      <div id='navbar-helper' className={clsx('app-navbar-item', itemClass)}>
        <Helper />
      </div>

      <div id='navbar-avatar' className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {TemaAtual === 'dark' ? (
            <img
              alt={currentUser?.name}
              className='object-fit-cover'
              src={
                currentUser?.avatar
                  ? process.env.REACT_APP_THEME_API_URL + '/storage/' + currentUser?.avatar
                  : toAbsoluteUrl('/media/svg/files/blank-image-dark.svg')
              }
            />
          ) : (
            <img
              className='object-fit-cover'
              alt={currentUser?.name}
              src={
                currentUser?.avatar
                  ? process.env.REACT_APP_THEME_API_URL + '/storage/' + currentUser?.avatar
                  : toAbsoluteUrl('/media/svg/files/blank-image.svg')
              }
            />
          )}
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n4' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
