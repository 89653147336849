import axios, {AxiosResponse} from 'axios'
import {MarkTutorialAsCompletedData, UserTutorial} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_TASK_URL = `${API_URL}/api/usuarios`

const markTutorialAsCompleted = ({
  userId,
  tutorialId,
}: MarkTutorialAsCompletedData): Promise<UserTutorial> => {
  return axios
    .put(`${GET_TASK_URL}/${userId}/tutorial/${tutorialId}/mark-as-completed`)
    .then((d: AxiosResponse<UserTutorial>) => d.data)
}

export {markTutorialAsCompleted}
