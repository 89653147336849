export const navbarSteps = [
  {
    element: '#navbar-my-tasks',
    title: 'Minhas tarefas',
    intro: 'Clique aqui para gerenciar suas tarefas.',
  },
  {element: '#navbar-search', title: 'Pesquisar', intro: 'Pesquise qualquer coisa no sistema.'},
  {
    element: '#navbar-new-task',
    title: 'Nova Tarefa',
    intro: 'Crie uma nova tarefa clicando aqui.',
  },
  {
    element: '#navbar-notifications',
    title: 'Notificações',
    intro: 'Visualize suas notificações.',
  },
  {element: '#navbar-theme', title: 'Tema', intro: 'Alterne entre os temas claro e escuro.'},
  {element: '#navbar-helper', title: 'Ajuda', intro: 'Acesse a ajuda.'},
  {
    element: '#navbar-avatar',
    title: 'Conta',
    intro: 'Clique no avatar para configurações e detalhes da conta.',
  },
]

export const sidebarSteps = [
  {
    element: '#sidebar-dashboard',
    title: 'Dashboard',
    intro: 'Aqui você pode ter uma visão geral da instância e monitorar o desempenho dos projetos.',
  },
  {
    element: '#sidebar-equipes-e-pessoas',
    title: 'Gerenciar Equipes e Pessoas',
    intro: 'Aqui você pode acessar e gerenciar as equipes e as pessoas associadas a sua instância.',
  },
  {
    element: '#sidebar-relatorios',
    title: 'Relatórios',
    intro:
      'Pesquise relatórios detalhados. Aqui você pode encontrar dados importantes rapidamente.',
  },
  {
    element: '#sidebar-projetos',
    title: 'Gestão de Projetos',
    intro:
      'Aqui você pode visualizar todos os projetos. Crie novos projetos e acesse detalhes específicos de cada um.',
  },
  {
    element: '#sidebar-sprints',
    title: 'Gestão de Sprints',
    intro:
      'Gerencie suas sprints, visualize tarefas associadas, usuários responsáveis, sprints abertas ou fechadas, e muito mais.',
  },
  {
    element: '#sidebar-cronogramas',
    title: 'Cronogramas',
    intro:
      'Aqui você pode visualizar e editar os cronogramas dos projetos, garantindo que todos os prazos sejam cumpridos de forma eficaz.',
  },
  {
    element: '#sidebar-timetracking',
    title: 'Acompanhamento de Tempo',
    intro: 'Monitore o tempo dedicado a tarefas específicas.',
  },
  {
    element: '#sidebar-clientes',
    title: 'Gestão de clientes',
    intro:
      'Aqui você pode visualizar todos os clientes. Crie novos clientes e acesse detalhes específicos de cada um.',
  },
  {
    element: '#sidebar-contratos',
    title: 'Gestão de Contratos',
    intro: 'Aqui você pode acessar e gerenciar seus contratos.',
  },
  {
    element: '#sidebar-wiki',
    title: 'Central de Ajuda e Wiki',
    intro:
      'Acesse posts e documente seus processos e projetos. Você pode trabalhar com posts, rascunhos, categorias e tags para organizar e centralizar o acesso às informações.',
  },
]
